<template>
    <div>
        <div class="category-menu-container">
            <ul class="nav" ref="dropdownMenu" v-click-outside="clearState">
                <li class="nav-item dropdown" v-for="(category, index) in filterOptions" :key="`category-${index}`">
                    <a :class="{ 'paint-hover': isActiveCategory(category)}"  class="nav-link dropdown-toggle subtitle-14 color-dark-main-navy" href="#" role="button" @click.prevent="toggleDropdown(category)">
                        {{ category.title }}
                        <span class="dropdown-toggle-icon" style="margin-left: -10px;"><icon-arrow :size="10" class="opacity-1 ms-3" :class="{'rotate paint-hover': isActiveCategory(category)}"/></span>
                    </a>
                </li>
            </ul>
        </div>
        <hr class="dropdown-divider">
        <div v-if="isActiveCategory(selectCategory)">
            <div class="overlay">
                <Card style="display: flex; align-items: center; justify-content: center;" :class="'position-relative'" class="cardStyle">
                    <div @click.stop>
                        <div class="mt-2" style="text-align: right; margin-right: 10px; position: relative; top: -15px;">
                            <span @click="clearState()">
                                <icon-arrow :size="30" class="opacity-1 ms-3 apply-hover" :class="{'rotate': isActiveCategory(selectCategory)}"/>
                            </span>
                        </div>

                        <div class="input-group h-100">
                            <input style="height: 33px;" v-model="selection" type="text" class="search-input" id="inputSearch" name="search" :placeholder="$t('layout.search_products')+'...'" @input="changes"  @keyup.enter="searchSelect()" autocomplete="off"/>
                            <button aria-label="Submit Form" type="submit" @click="searchSelect()" class="global-search">
                                <icon-search color="white" :size="15"/>
                            </button>
                        </div>

                        <div class="d-flex wrapper" style="gap: 20px; position: relative; top: 13px;">
                            <ul class="nav flex-column ps-0 position_li_2" v-for="(subCategory, i) in selection ? filteredCategories : mutateBrand" :key="`subcategory-${i}`">
                                <li class="size-17 weight-600 mt-3 mb-2 pb-1 px-0" @click="viewBrand(subCategory.name)">
                                    <span>{{ subCategory.name | textFormat }}</span>
                                </li>
                                <span class="lineStyle" style=" position: relative; top: -9px;" />
                                <!-- <li data-toggle="tooltip" data-placement="top" class="d-block mb-1" v-for="(category, i) in subCategory.subcategories" :key="`subcategory-${i}`">
                                    <span class="d-block size-14 text-limited" :class="'text-sm-start align-content-md-start align-content-lg-center'">{{ category.name | textFormat }}</span>
                                </li> -->
                            </ul>
                        </div>

                        <div v-if="!notFound" class="row grid-container position-relative"  style="overflow-y: auto; max-height: 500px; top: 26px; gap: 20px;">
                            <div  v-for="product in products" :key="product.codarticulo" class="col-3 col-sm-3 col-xl-2 mt-1">
                                <card-product :product="product" :skeleton="loading" @showQuickView="showQuickView" @showShare="showShare" />
                            </div>

                            <div v-if="!loading" class="d-flex flex-column text-center justify-content-center mt-5">
                                <a class="color-blue-600 size-16" @click="viewCategory(selectCategory.value)">{{$t('layout.see_more_products')}}</a>
                            </div>
                        </div>

                        <without-results v-else class="mt-5" description="home.category_notFound" title="product.no_product_was_found" noButton>                
                            <template #main-icon>
                                <icon-no-product :size="90"/>
                            </template>
                            <template #button-icon>
                                <icon-change class="fill-white me-2" :size="17" />
                            </template>
                        </without-results>
                    </div>
                </Card>
            </div>
        </div>
        <quick-view v-if="quickView.value" :product="quickView.product" @closeModal="quickView.value = false" />
        <share v-if="shareProduct.show" :product="shareProduct.product" @closeModal="shareProduct.show = false" />
    </div>
</template>

<script>
import Card from '../../../../common/components/cards/Card.vue';
import iconSearch from '../../../../common/svg/iconSearch.vue';
import WithoutResults from '../../../../common/components/modals/WithoutResults.vue';
import { ProductService } from '../../../products/services/product-service';
import CardProduct from '../../../products/components/CardProduct.vue';
import iconNoProduct from '../../../products/assets/svg/iconNoProduct.vue';
import { mapGetters } from 'vuex';
import _  from "lodash"
import Share from '../../../products/components/modals/Share.vue';
import QuickView from '../../../products/components/quick-view/QuickView.vue';
const service = new ProductService();
export default {
    name: "MainCategoryNavBar",
    components: {
    IconArrow: () => import("@/common/svg/iconArrow"),
    iconSearch,
    Card,
    CardProduct,
    WithoutResults,
    iconNoProduct,
    Share,
    QuickView
},
    data() {
        return {
            selectCategory: {},
            categories: [],
            selectedCategory: null,
            selection: '',
            isActive: false,
            loadingWithScrolling: false,
            filteredCategories: null,
            loading: true,
            notFound: false,
            quickView: {product: null, value: false},
            shareProduct: {
                show: false,
                product: null
            },
            products: [],
            page: 0,
            brand: {},
            limit: 6,
            // iconNoProduct,
            searchWithDebounce: _.debounce(async function () {
                await this.setProducts(this.selectCategory.value);
            }, 500),
        };
    },
    computed: {
        ...mapGetters({
            filters: "products/filters",
            branchSelected: "branches/branchSelected",
        }),
        filterOptions(){
            const categories = [];
            const options = this.categories;
            for(let option of options) {
                categories.push({
                    value: option.codcategory,
                    title: this.$options.filters.textFormat(option.description)
                });
            }
            return categories
        },
        mutateBrand(){
            const mutate = this.brand
            if(mutate){
                const newObj = Object.entries(mutate).map(([name, id]) => ({ name, id }));
                return newObj.slice(0, this.limit);
            }
            return ''
        }
    },
    watch: {
        'selection'(){
            this.searchSelect()
        },
    },
    methods: {
        clearState(){
            this.isActive = false
            this.selectCategory = {}
            this.selectedCategory = null
        },
        changes(){
            this.searchWithDebounce();
        },
        async toggleDropdown(category) {

            this.selectCategory = category
            this.selectedCategory = this.selectedCategory === category ? null : category;
            await this.setProducts(category.value);
        },
        viewCategory(description){
            this.filters.category = description
            this.$router.replace({path: `shop?page=1&category=${description}&limit=24&order_by=popularity&place=1&only_with_existence=1`}).catch(() => {});
        },
        viewBrand(description){
            this.filters.brands_selected = [description]
            this.filters.category = this.selectedCategory.value
            this.$router.replace({path: `shop?page=1&brands_selected=${description}&limit=24&order_by=popularity&place=1&only_with_existence=1`}).catch(() => {});
        },
        isActiveCategory(category) {
            return this.selectedCategory === category;
        },
        searchSelect(){
            this.search(this.selection)
        },
        search(query) {
            this.filteredCategories = this.mutateBrand?.filter(category => {
            if (category.name.toLowerCase().includes(query.toLowerCase())) {
                return true;
            }
                return false;
            });
        },
        async setProducts(category, brand){
            let branch = this.branchSelected?.codplace || 1;
            let filterParams = { q: this.selection, order_by: 'newest', category, limit: 18, prices: [0, 100000], gbSelected: [], place: branch, page: 1, optional_category_filter: 0,  only_with_existence: 1, brands_selected: brand };
            try {
                const response = await service.getProducts(filterParams);
                this.products = response.docs;
                this.brand = response.facets.brand;
            } catch (e) {
                console.log(e);
            } finally {
                this.notFound = this.products.length < 1;
                if(this.$refs?.productSwiper)
                this.$refs.productSwiper.$swiper.slideTo(0,0)
                this.loading = false;
            }
        },
        async getCategories(){
            let filterParams = {  type: 'subcategory' , place: 1, only_with_existence: 1 };
            try {
                const response = await service.getProducts(filterParams);
                this.categories = response
            } catch (e) {
                console.log(e);
            }
        },
        showShare(product){
            this.shareProduct = {
                product: product,
                show: true
            }
        },
        showQuickView({product, value}){
            this.quickView.product = product;
            this.quickView.value = value;
        },
    },
    async mounted(){
        this.getCategories()
    },
};
</script>

<style scoped>

.wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

::-webkit-scrollbar {
    height: 0;
}

.apply-hover:hover {
    fill: var(--main-red);
    color: var(--main-red) !important;
    opacity: 3 !important;
}

.paint-hover {
    fill: var(--main-red);
    color: var(--main-red) !important;
    opacity: 3 !important;
}

ul.nav li:hover > span {
    transform: translateX(10px) !important;
    transition: transform 0.3s ease;
    color: var(--main-red)
}

.ul {
    color: var(--main-navy);
}
.category-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dropdown-menu {
    display: contents;
}

.dropdown-menu[style*="display: contents;"] {
    display: contents !important;
}
.dropdown-divider {
    width: 100%;
    margin: -3px 0;
    border: none;
    border-top: 3px solid var(--main-navy);
    margin-bottom: 10px;
}
.rotate {
	transform: rotate(-90deg) translate(0%, 0%) !important;
}
.icon-arrow {
    transition-timing-function: ease-in-out;
    transition: var(--transition-1);
	transform: rotate(90deg) translate(0%, 0%);
}
.dropdown-toggle::after {
    display: none;
}

.search-input {
    border: unset;
    background: whitesmoke; 
    border-start-start-radius: 5px; 
    border-bottom-left-radius: 5px;
}

.overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.lineStyle {
    content: '';
    height: 4px;
    border-radius: 3.125rem;
    width: 123px;
    background-color: var(--main-red);
}

.cardStyle {
    max-width: 1300px;
    position: relative; 
    top: 3px;
    box-shadow: 2px 3px 15px 0px rgba(14, 30, 61, 0.1411764706);
    border-radius: 14px;
}
.swiper, .slider-column { /* height of the general swiper*/
    height: 318px;
}

@media (min-width: 768px) {
    .cardStyle{
        width: 800px !important;
    }
}
@media (min-width: 992px) {
    .cardStyle{
        width: 941px !important;
    }

    /* .overlay{
        top: 190px !important;
    } */
}
@media (min-width: 1200px) {
    .cardStyle{
        width: 1150px !important;
    }
}

@media (min-width: 1400px) {
    .cardStyle{
        width: 1300px !important;
    }
}
</style>
